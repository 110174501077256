import React from "react";

function LoadBackground() {

  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [text1, setText1] = React.useState('')
  const [text2, setText2] = React.useState('')
  const [text11, setText11] = React.useState('')
  const [text21, setText21] = React.useState('')
  const [text31, setText31] = React.useState('')
  const [text41, setText41] = React.useState('')
  const [text51, setText51] = React.useState('')
   
  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const csnid = queryParams.get("csnid")
    const esc = queryParams.get("es")
    // const url = "http://localhost:8080/api/v1/ackmessage?csnid="+csnid;
    //const url = "http://localhost:8210/csnservice/acknowledge?csnid=" + csnid;
    //const url = "https://ppe-messages.etihadcargo.com/services/acknowledge?csnid=" + csnid+"&es="+esc;
    const url = "https://messages.etihadcargo.com/services/acknowledge?csnid=" + csnid+"&es="+esc;
    setTimeout(() => {
      getAckMessage(url);
    }, 7000);
  }, []);

  const getAckMessage = (url) => {
    fetch(url, {
      method: 'POST',
      mode: 'cors'
    })
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => console.log("ackmessage error-> ", error));
  }

  React.useEffect(() => {
    console.log(data,'loadeddata')
    if (data.length !== 0) {
      setIsLoading(false);
     
    }
    if(data.message){
      const text = data.message.split('#');
      setText11(text[0])
      setText21(text[1])
      setText31(text[2])
      setText41(text[3])
      setText51(text[4])
    }
   
  }, [data]);
 

 
    return (
      <div>
      {isLoading ? (
      <h1>fetching acknowledgement status...</h1>
      ) : (<div style={{ marginLeft: "50px", textAlign: "left" }}>
      <h3> 
        <p>{text11}</p>
      <p>{text21}</p>
      <p>{text31}</p>
      <p>{text41}</p>   
      <p>{text51}</p> 
      </h3>
      
      </div>)}
      
      </div>
      
      );

}

export default LoadBackground;