import { useState, useEffect } from 'react';
import footerLogo from '../../assets/images/footer-text-logo.svg'
import './footer.scss'

const Footer = () => {
    const [countryCode, setCountryCode] = useState("");

    useEffect(() => {
        const url = "https://messages.etihadcargo.com/edge-services/countryCode";
        getCountryCode(url);
    }, []);

    const getCountryCode = (url) => {
        fetch(url,{
            method: 'GET', 
            mode: 'cors'})
        .then((response) => response.json())
        .then((json) => setCountryCode(json.countrycode))
        .catch((error) => console.log("CountryCode error-> ",error));
    }

    return (
        <div className='cargo-portal-container'>
            <div
                className='container-fluid cargo-portal-footer'
                id='cargo-portal-footer'
            >
                    <div style={{marginTop:"20px" }} className='navigation_footer_logo'>
                        <div className='atomic-paragraph'>
                            <img className='image' 
                                src={footerLogo}
                                alt='Footer Logo Alternate Text'
                            />
                        </div>
                       
                        <div className='navigation__copyright'>
                            
                    <div className='navigation_language'>
                        <div className='language'>
                            <div className='footer-lang-sel-parent'>
                                <div className='footer-lang-sel-btn-grp-main atomic-paragraph-color'></div>
                            </div>
                        </div>
                        <div  style={{marginLeft:"50px",paddingBottom:"80px"}}className='navigation_links navigation_links_top'>
                            <a
                                className='navigation__link '
                                href='https://www.etihadcargo.com/en/terms-and-conditions'
                            >
                                Terms and Conditions 
                            </a> 
                            <a
                                className='navigation__link '
                                href='https://www.etihadcargo.com/en/privacy-policy'
                            >
                                   | Privacy Policy 
                            </a> 
                            <a className='navigation__link' href='https://www.etihadcargo.com/en/cookie-policy'>
                                   | Cookie Policy 
                            </a> 
                        </div>
                        {(countryCode === "cn" || countryCode === "zh") || (countryCode === "CN" || countryCode === "ZH") ? 
                            <div className='navigation_links'>
                                <a
                                    className='navigation__link '
                                    href='https://beian.miit.gov.cn/'
                                >
                                    | Shanghai, ICP prepared No. 14027180
                                </a>
                            </div>
                        : ""}
                    </div>
                 </div>
                    <div className='atomic-paragraph'>
                        <p className='atomic-paragraph-color'>Copyright {new Date().getFullYear()} Etihad Airways. All rights reserved</p>
                        </div>
                   </div>
                </div>
            </div>
       
    )
}

export default Footer